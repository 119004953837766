import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import UserAccount from './UserAccount.js';
import SidebarItem from './SidebarItem.js';
import styles from './Sidebar.module.scss';
import {ReactComponent as LogoSvg} from './momo_logo.svg';
import {ReactComponent as ManagerSvg} from './momo_manager.svg';
import {ReactComponent as UserSvg} from './momo_user.svg';
import {ReactComponent as RentalSvg} from './momo_rental.svg';
import {ReactComponent as PaymentsSvg} from './momo_payments.svg';
import {ReactComponent as PauseSvg} from './momo_pause.svg';
import {ReactComponent as CodeSvg} from './momo_code.svg';
import {ReactComponent as DailyStatementsSvg} from './momo_daily_statements.svg';
import {ReactComponent as DepositSvg} from './momo_deposit.svg';
import {ReactComponent as BankingAccountSvg} from './momo_banking_account.svg';
import {ReactComponent as CampaignSvg} from './momo_campaign.svg';
import {ReactComponent as ImportSvg} from './momo_import.svg';

export default function Sidebar() {
  const {is_admin} = useSelector((state) => state.user.account);
  return (
    <div className={styles.sidebar} data-test="sidebar">
      <div>
        <Link to="/">
          <div className={styles.logo}>
            <LogoSvg />
          </div>
        </Link>
        <div className={styles.item_wrapper}>
          <SidebarItem name="Managers" to={BACKOFFICE_PATHS.managers}>
            <ManagerSvg />
          </SidebarItem>

          <SidebarItem
            name="Rental Contracts"
            to={BACKOFFICE_PATHS.rental_contracts}>
            <RentalSvg />
          </SidebarItem>

          <SidebarItem name="Deposits" to={BACKOFFICE_PATHS.deposits}>
            <RentalSvg />
          </SidebarItem>

          {is_admin && (
            <SidebarItem
              name="Deposit Guarantee Applications"
              to={BACKOFFICE_PATHS.deposit_guarantee_applications}>
              <DepositSvg />
            </SidebarItem>
          )}

          <SidebarItem
            name="Property Owners"
            to={BACKOFFICE_PATHS.property_owners}>
            <ManagerSvg />
          </SidebarItem>

          <SidebarItem name="Claims" to={BACKOFFICE_PATHS.claims}>
            <DailyStatementsSvg />
          </SidebarItem>

          <SidebarItem
            name="Deposit Accounts"
            to={BACKOFFICE_PATHS.deposit_accounts}>
            <PaymentsSvg />
          </SidebarItem>

          <SidebarItem
            name="Deposit Account Vibans"
            to={BACKOFFICE_PATHS.deposit_account_vibans}>
            <BankingAccountSvg />
          </SidebarItem>

          <SidebarItem
            name="Unallocated Payments"
            to={BACKOFFICE_PATHS.unallocated_payments}>
            <PauseSvg />
          </SidebarItem>

          {is_admin && (
            <SidebarItem
              name="Cash Deposit Import"
              to={BACKOFFICE_PATHS.cash_deposit_import}>
              <ImportSvg />
            </SidebarItem>
          )}

          {is_admin && (
            <>
              <SidebarItem
                name="VVRB Requests"
                to={BACKOFFICE_PATHS.vvrb_requests}>
                <CodeSvg />
              </SidebarItem>

              <SidebarItem name="VVRB Events" to={BACKOFFICE_PATHS.vvrb_events}>
                <CodeSvg />
              </SidebarItem>

              <SidebarItem
                name="EverReal Events"
                to={BACKOFFICE_PATHS.everreal_events}>
                <CodeSvg />
              </SidebarItem>

              <SidebarItem
                name="Covivio Events"
                to={BACKOFFICE_PATHS.covivio_events}>
                <CodeSvg />
              </SidebarItem>

              <SidebarItem
                name="Tenant Promotion Campaigns"
                to={BACKOFFICE_PATHS.tenant_promotion_campaigns}>
                <CampaignSvg />
              </SidebarItem>

              <SidebarItem
                name="Backoffice Users"
                to={BACKOFFICE_PATHS.backoffice_users}>
                <UserSvg />
              </SidebarItem>
            </>
          )}
        </div>
      </div>

      <UserAccount />
    </div>
  );
}
